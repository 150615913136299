import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import FoundBlock from '../components/FoundBlock';
import FoundForm from '../components/FoundForm';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const FidTemplate = ({ code }) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t('youFoundATaggi')} />
      <section className="container contrast flex-col lg:flex-row px-4 py-16">
        <div className="flex">
          <div className="self-center">
            <FoundBlock code={code} />
          </div>
        </div>
        <div className="my-8 lg:my-0 lg:mx-8 flex items-center justify-center">
          <div className="h-full w-px bg-black" />
          <div className="w-full h-px bg-black" />
          <div className="absolute p-4 leading-3 bg-gray-200 text-lg">
            <strong>
              {t('or')}
            </strong>
          </div>
        </div>
        <div className="flex">
          <div className="self-center">
            <FoundForm code={code} />
          </div>
        </div>
      </section>
    </Layout>
  );
};

FidTemplate.propTypes = {
  code: PropTypes.string.isRequired,
};

export default FidTemplate;
